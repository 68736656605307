<template>
    <div class="col header industrypadding">
        <h1 class="MobileHeader">AZ IPARÁG</h1>
        <h3 class="subheader">PREMIUM ÉS LUXUS AUTÓKÖLCSÖNZÉS</h3>
    </div>
    <div class="col padleft">
        <div class="row mobilegrid">
            <div class="col-lg-6 col bg-stairs">
                <div class="col scaleback">
                    <div class="col pt-1stair">
                        Iparág éves növekedési <br class="MobileDisplay"> rátája: 20%
                    </div>
                    <div class="col pt-2stair">
                        Dubai piac éves növekedési<br class="MobileDisplay"> rátája: 22.5%
                    </div>
                    <div class="col pt-3stair">
                        2026-2027 évre projektált piaci érték: <br class="MobileDisplay">60 Milliárd $
                    </div>
                    <div class="col pt-4stair">
                        Dubai/UAE piac mérete 2021-ben:<br class="MobileDisplay"> 600 Millió $
                    </div>
                    <div class="col pt-5stair">
                        Jelenlegi világpiaci méret:<br class="MobileDisplay"> 20 Milliárd $
                    </div>
                </div>
            </div>
            <div class="col-lg-6 LGdisplay col list">
                <ul>
                    <li class="pb-4">
                        <span class="listtext">Létrehozunk egy UAE Céget számodra, vagy rezidens vízumot, és bankszámlát
                            igényeid szerint, így a bérbeadásból származó jövedelmedhez 0% adóval férhetsz hozzá.</span>
                    </li>
                    <li class="pb-4">
                        <span class="listtext">Kiajánlunk neked egy listát a potenciális gépjárművekről a befektetésre
                            szánt összegednek megfelelően, a piaci kereslet alapján.</span>
                    </li>
                    <li class="pb-4">
                        <span class="listtext">A Dubai Ride bonyolítja le a teljes vásárlási, regisztrációs
                            folyamatot.</span>
                    </li>
                    <li class="pb-4">
                        <span class="listtext">A Dubai Ride bonyolítja le a flotta operatív irányítását, a bérbeadások
                            menedzsmentjét.</span>
                    </li>
                    <li class="pb-4">
                        <span class="listtext">Az összes marketing és operatív költésg a Dubai Ride-nál
                            jelentkezik.</span>
                    </li>
                    <li>
                        <span class="listtext">A bérbeadőásból származó bevétel megosztása: 60-40%</span>
                    </li>
                </ul>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    name: 'IndustryComponent',
};
</script>

<style>
@import "/src/css/industrycomponent.css";
</style>