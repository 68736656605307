<template>
<div class="col pb-lg-5 ferrari">
    <div class="col header pb-lg-4 pb-3">
       <h1 class="MobileHeader">MIÉRT VÁLASSZ <span class="yellow">MINKET?</span></h1>
    </div>
        <div class="col-lg-5 col whyus pb-4">
2023-ban Dubai várhatóan 20 millió turistát fog fogadni, ami jelentősen hozzájárul
a luxusautó-kölcsönző iparág virágzásához. Cégünk olyan platformot biztosít a biztonságos
és hosszú távú befektetésekhez, mellyel akár Magyarországról is el tudja
indítani saját luxusautó-kölcsönző vállalkozását.
</div>
<div class="col-lg-5 col whyus pb-4">
Cégünk Dubai székhellyel, saját alkalmazottakkal és kiterjedt kapcsolatrendszerrel
rendelkezik, helyi jelenlétünknek köszönhetően biztonsággal irányítjuk és kezeljük
a luxusautó flottánkat.
</div>
<div class="col MobileDisplay">
<div class="col-lg-5 col whyus pb-4">
Létrehozunk egy UAE Céget számodra, vagy rezidens vízumot, és bankszámlát igényeid szerint, így a bérbeadásból származó jövedelmedhez 0% adóval férhetsz hozzá.
</div>
<div class="col-lg-5 col whyus pb-4">
Kiajánlunk neked egy listát a potenciális gépjárművekről a befektetésre szánt összegednek megfelelően, a piaci kereslet alapján.
</div>
<div class="col-lg-5 col whyus pb-4">
A Dubai Ride bonyolítja le a teljes vásárlási, regisztrációs folyamatot.
</div>
<div class="col-lg-5 col whyus pb-4">
A Dubai Ride bonyolítja le a flotta operatív irányítását, a bérbeadások menedzsmentjét.
</div>
<div class="col-lg-5 col whyus pb-4">
Az összes marketing és operatív költésg a Dubai Ride-nál jelentkezik.
</div>
<div class="col-lg-5 col whyus">
A bérbeadőásból származó bevétel megosztása: 60-40%
</div>
</div>
</div>
</template>

<script>
export default {
    name: 'CarComponent',
};
</script>

<style>
@import "/src/css/carcomponent.css";
</style>