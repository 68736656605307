<template>
<div class="MobilePadding">
<SkyScraper />
<div class="bg-curve">
<CarComponent />
<div class="bg-midcurve">
<IndustryComponent />
<MoneysaveComponent />
<ContactComponent />
</div>
</div>
</div>
</template>

<script>

export default {
  name: 'App',
  components: {
        SkyScraper,
        CarComponent,
        IndustryComponent,
        ContactComponent,
        MoneysaveComponent,
    }
}

import SkyScraper from './components/SkyScraper.vue';
import CarComponent from './components/CarComponent.vue';
import IndustryComponent from './components/IndustryComponent.vue';
import ContactComponent from './components/ContactComponent.vue';
import MoneysaveComponent from './components/MoneysaveComponent.vue';
</script>

<style>
@import "/src/css/app.css";
</style>
