<template>
    <div class="col-lg-9 col mx-auto text-uppercase header moneybackpadding">
        Befektetés megtérülési ráta
    </div>
    <div class="col-lg-9 col mx-auto">
        <div class="row">
            <div class="col-lg-4 col">
                <div class="row">
                    <div class="col fontsizefirst g-0 text-uppercase">Befektetési <br> évek</div>
                    <div class="col fontsizesecond g-0 text-uppercase">A befektetés várható megtérülése</div>
                </div>
                <div class="row">
                    <div class="col-lg-5 col g-0 text-start">
                        <div class="col-8 yellowpill mt-3">1.év</div>
                        <div class="col-8 yellowpill">2.év</div>
                        <div class="col-8 yellowpill">3.év</div>
                        <div class="col-8 yellowpill">4.év</div>
                    </div>
                    <div class="col-lg-5 col g-0 text-start">
                        <div class="col-8 greypill mt-3">45-50% </div>
                        <div class="col-8 greypill">40-45%</div>
                        <div class="col-8 greypill">35-40%</div>
                        <div class="col-8 greypill">30-35%</div>
                    </div>
                </div>
                <div class="row pb-4 my-auto">
                    <div class="col-lg-5 col text-start borders g-0">
                        <div class="leftover my-auto">Maradványérték <br> a befektetésben</div>
                    </div>
                    <div class="col-5 text-start borders g-0">
                        <div class="text-end rightover my-auto">40-45%</div>
                    </div>
                </div>
                <div class="row">
                    <div class="col-lg-5 col text-start borders g-0">
                        <div class="leftover my-auto">1. Teljes befektetési <br> ciklus</div>
                    </div>
                    <div class="col-5 text-start borders g-0">
                        <div class="text-end rightover my-auto text-end">195-225%</div>
                    </div>
                </div>
            </div>
            <div class="col-2 LGdisplay"></div>
            <div class="col-lg-6 LGdisplay g-0">
                <img class="diaposition img-fluid" src="../assets/diagram.png" alt="">
                <div class="row pt-3 g-0">
                    <div class="col">1.év</div>
                    <div class="col">2.év</div>
                    <div class="col">3.év</div>
                    <div class="col">4.év</div>
                </div>
            </div>
        </div>
        <div class="statguess">A becslés az elmúlt 4 év statisztikai adatain alapszik.</div>
    </div>
</template>

<script>
export default {
    name: 'MoneysaveComponent',
};
</script>

<style>
@import "/src/css/moneysavecomponent.css";
</style>