<template>
  <div class="col header contactpadding pb-5">
    <h1 class="MobileHeader">KAPCSOLAT</h1>
  </div>
  <div class="shadowbg">
    <div class="LGdisplay">
      <div class="col-lg-5 col mx-auto pb-5">
        <div class="row pb-4">
          <div class="col-2 mx-auto yellowdot mail"></div>
          <div class="col-2 mx-auto yellowdot mobile"></div>
          <div class="col-2 mx-auto yellowdot location"></div>
        </div>
        <div class="row height">
          <div class="col-3 mx-auto">
            <a class="contacttext" href="mailto:info@dubairide.me"
              >info@dubairide.me</a
            >
          </div>
          <div class="col-3 mx-auto height">
            <div class="col">
              <a class="contacttext" href="tel:+36305315811">+36305315811</a>
            </div>
            <div class="col">
              <a class="contacttext" href="tel:+971568951222">+971568951222</a>
            </div>
          </div>
          <div class="col-3 mx-auto">
            <a
              id="map"
              class="contacttext"
              href="https://maps.google.com/?q=Clover Bay Tower"
              target="_blank"
              >Clover Bay Tower, Dubai, Business Bay</a
            >
          </div>
        </div>
      </div>
    </div>
    <div class="MobileDisplay">
      <div class="row pb-4">
        <div class="col-3">
          <div class="col-6 mobileyellow mail">

          </div>
        </div>
        <div class="col-6 m-auto">
          <a class="contacttext" href="mailto:info@dubairide.me"
            >info@dubairide.me</a
          >
        </div>
        <div class="col-3"></div>
      </div>
      <div class="row pb-4">
        <div class="col-3">
          <div class="col-6 mobileyellow mobile"></div>
        </div>
        <div class="col-6 mobileline m-auto">
          <a class="contacttext" href="tel:+36305315811">+36305315811 <br /></a>
          <a class="contacttext" href="tel:+971568951222">+971568951222</a>
        </div>
        <div class="col-3"></div>
      </div>
      <div class="row pb-4">
        <div class="col-3">
          <div class="col-6 mobileyellow location">

          </div>
        </div>
        <div class="col-6 mobileline m-auto">
          <a
            id="map"
            class="contacttext"
            target="_blank"
            href="https://maps.google.com/?q=Clover Bay Tower"
            >Clover Bay Tower, Dubai, Business Bay</a
          >
        </div>
        <div class="col-3"></div>
      </div>
    </div>
    <div class="col pb-button">
      <a href="mailto:info@dubairide.me">
      <button
        type="button"
        class="contactbutton"
      >
        AJÁNLATKÉRÉS
      </button>
      </a>
    </div>
    <div class="row mx-auto justify-content-center copyright pb-4">
    </div>
  </div>
</template>

<script>

export default {
  name: "ContactComponent"
};
</script>

<style>
@import "/src/css/contactcomponent.css";
</style>