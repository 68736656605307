<template>
<div class="col bg-skyscraper">
    <div class="row justify-content-between pt-lg-5 pt-4">
  <div class="col-lg-1 dot">
  </div>
  <div class="col-lg-2 col">
    <img class="logo" src="../assets/logo.png" alt="">
  </div>
</div>
    <div class="col header pt-7em">
      <div class="col">
        <img class="MobileDisplay MobileSkyscraper" src="../assets/skyscraper.png" alt="">
      </div>
        <h1 class="MobileHeader">MIÉRT LUXUSAUTÓ KÖLCSÖNZÉS</h1>
        <h1 class="MobileHeader">- MIÉRT ÉPPEN <span class="yellow">DUBAI?</span></h1>
        <div class="col mobileferrari"></div>
    </div>

  <div class="col blur">
    <p class="p-lg-4">A luxusautók bérlése iránti kereslet évről évre, folyamatosan
növekvő tendenciát mutat és a jelenlegi piacon lévő készlet
alig elégíti ki a keresletet. 
Jelenleg a luxusautó-kölcsönzési
üzletágba történő befektetés Dubaiban az egyik, ha nem a
legjobb lehetőség.</p>
</div>
</div>
</template>

<script>
export default {
    name: 'SkyScraper',
};
</script>

<style>
@import "/src/css/skyscraper.css";
</style>